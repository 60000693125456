import React from 'react'
import {
  Container,
  Header,
  Paragraph,
  Overview
} from './TailorMade.elements'

export default function TailorMade () {
  return (
    <Container withSpacing>
      <Header>Skræddersyet indkøbsportal</Header>
      <Paragraph>Fra start til slut er spacemasters indkøbsportal tilpasset dit behov. Ingen overflødig funktionalitet. Kun det, du har brug for.</Paragraph>
      <Overview />
    </Container>
  )
}
