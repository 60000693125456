import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Button } from '../../globalStyles'
import { StaticImage } from 'gatsby-plugin-image'
import SpacemasterLogo from '../../images/logo.svg'

const Container = styled.div`
  background: ${({ theme }) => theme.colors.mountainmeadow};
  overflow: hidden;
`

const Navigation = styled.nav`
  margin-bottom: 2rem;
`

const Logo = styled(Link)`
  display: block;
  width: 9.75em;
  height: 0.8125em;
  background: url(${SpacemasterLogo}) no-repeat;
  background-size: contain;
`

const InnerContainer = styled.div`
  padding: 2rem 0 4rem;
  width: 85%;
  max-width: 75em;
  margin: 0 auto;
`

const Content = styled.article`
  display: grid;
  place-items: center;
  grid-template-columns: repeat(12, 1fr);
  color: ${({ theme }) => theme.colors.white};

  h1 {
    font-size: 2.25rem;
    line-height: 1.2;
    margin-bottom: 1.25rem;

    @media (min-width: 73em) {
      font-size: 3.4375rem;
      line-height: 1.2;
    }
  }

  h2 {
    font-weight: 200;
  }

  p {
    font-weight: 400;
    font-size: 1rem;
  }

  ${Button} + p {
    font-weight: 600;
  }

  * + * {
    margin-bottom: 1.25rem;
  }
`

const Column = styled.div`
  grid-column: 1 / -1;
  @media (min-width: 46em) {
    grid-column: 1 / 6;
  }
`

const Figure = styled.figure`
  margin: 0;
  margin-left: -1.2em;
  margin-right: -1.2em;
  grid-column: 1 / -1;
  margin-bottom: -30%;
  @media (min-width: 46em) {
    max-width: 30vw;
    grid-column: 7 / -1;
    margin-bottom: 0;
  }
`

export default function Hero ()  {
  return (
    <Container>
      <InnerContainer>
        <Navigation>
          <Logo to="#" />
        </Navigation>
        <Content>
          <Column>
            <h1>Indkøbsportal målrettet mange lokationer</h1>
            <p className="skinny">Med spacemaster får du en indkøbsportal, hvor du beslutter, hvilke varer dine kolleger kan købe efter fælles retningslinjer</p>
            <Button href="mailto://jacob.meibom@crowdmedia.dk?Subject=Spacemaster.io gratis i 3 måneder" target="_top">Prøv <b>Spacemaster.io</b> gratis i 3 måneder</Button>
          </Column>
          <Figure>
            <StaticImage
              src="../../images/hero.png"
              placeholder="none"
              loading="eager"
              backgroundColor="transparent"
              imgStyle={{objectFit: 'contain'}}
              outputPixelDensities={2}
              quality={100}
              alt=""
            />
          </Figure>
        </Content>
      </InnerContainer>
    </Container>
  )
}