import React from 'react'
import { List, ListItem } from '../../globalStyles'
import { StaticImage } from 'gatsby-plugin-image'
import { Container, Content, Header, Paragraph } from './Section.elements'

export function ProduktKatalog () {
  return (
    <Container>
      <Content>
        <Header>Produktkatalog</Header>
        <Paragraph>Vi sammensætter et produktkatalog skræddersyet efter dine behov. Du bestemmer, hvilke varer indkøbsportalen tilbyder dine kolleger.</Paragraph>
        <List bulletColor="texasrose">
          <ListItem>Kun relevante varer</ListItem>
          <ListItem>Du afgør, om dine kolleger skal kunne se indkøbspriser</ListItem>
        </List>
      </Content>
      <StaticImage
        src="../../images/produkt_katalog_big.png"
        alt="Produktkatalog"
        outputPixelDensities={2}
        quality={100}
      />
    </Container>
  )
}

export function LayoutSkabeloner () {
  return (
    <Container reversed>
      <Content>
        <Header>Layoutskabeloner</Header>
        <Paragraph>Med spacemaster kan du få layoutskabeloner, således at tryksager af enhver art kan individualiseres og bestilles lokalt uden at miste kontrollen med dit brand. Du sikrer hermed, at dine design guides altid overholdes, selvom flere får adgang til at købe produkter med tryk.</Paragraph>
        <List bulletColor="mountainmeadow">
          <ListItem>Alle kan lave trykklare filer  uden hjælp fra grafisk medarbejder</ListItem>
          <ListItem>Alle kan bestille uden at ændre design guides</ListItem>
        </List>
      </Content>
      <StaticImage
        src="../../images/layoutskabeloner_big.png"
        alt="Layoutskabeloner"
        outputPixelDensities={2}
        quality={100}
      />
    </Container>
  )
}

export function Bestilling () {
  return (
    <Container>
      <Content>
        <Header>Bestilling og valg af modtagere</Header>
        <Paragraph>Når du køber varer, kan du let fordele, hvordan en bestilling skal distribueres mellem flere lokationer.</Paragraph>
        <List bulletColor="bleudefrance">
          <ListItem>Hovedkontoret kan bestille og distibuere til en eller flere afdelinger</ListItem>
          <ListItem>Hver afdeling kan bestille til eget forbrug</ListItem>
        </List>
      </Content>
      <StaticImage
        src="../../images/bestilling_modtagere_big.png"
        alt="Bestilling og valg af modtagere"
        outputPixelDensities={2}
        quality={100}
      />
    </Container>
  )
}

export function TrackAndTrace () {
  return (
    <Container reversed>
      <Content>
        <Header>Track and Trace</Header>
        <Paragraph>Når du har gennemført en bestilling, får du et online overblik over leveringsstatus for hver enkelt lokation. Alle modtagere har online adgang til den samme information og du slipper derfor for at skulle besvare spørgsmål fra modtagere om leveringsstatus.</Paragraph>
        <List bulletColor="mediumslateblue">
          <ListItem>Fuldt overblik over aktuel leveringsstatus</ListItem>
          <ListItem>Slip for at forstyrre med hinanden med spørgsmål om status</ListItem>
        </List>
      </Content>
      <StaticImage
        src="../../images/tnt_big.png"
        alt="Track and Trace"
        outputPixelDensities={2}
        quality={100}
      />
    </Container>
  )
}

export function Genbestilling () {
  return (
    <Container>
      <Content>
        <Header>Genbestilling</Header>
        <Paragraph>Hvis en lokal modtager af varer oplever fejl i leverancen eller transportskade, kan modtageren let selv indrapportere fejlen og foretage en genbestilling.</Paragraph>
        <List bulletColor="mountainmeadow">
          <ListItem>Simpel genbestilling</ListItem>
          <ListItem>Enkel reklamation</ListItem>
        </List>
      </Content>
      <StaticImage
        src="../../images/genbestilling_big.png"
        alt="Genbestilling"
        layout="fullWidth"
        outputPixelDensities={2}
        quality={100}
      />
    </Container>
  )
}

export function Budgetkontrol () {
  return (
    <Container reversed>
      <Content>
        <Header>Budgetkontrol</Header>
        <Paragraph>Hver indkøber tildeles et budget og du kan indlægge regler for godkendelse af den enkelte medarbejder, der forhindrer utilsigtet overskridelse af budget.</Paragraph>
        <List bulletColor="bleudefrance">
          <ListItem>Fleksibilitet uden anarki</ListItem>
          <ListItem>Ingen utilsigtede budgetoverskridelser</ListItem>
          <ListItem>Budgetgrænser pr. Indkøber, pr afdeling eller pr varegruppe</ListItem>
        </List>
      </Content>
      <StaticImage
        src="../../images/budgetkontrol_big.png"
        alt="Budgetkontrol"
        outputPixelDensities={2}
        quality={100}
      />
    </Container>
  )
}