import React from 'react'
import styled from 'styled-components'
import { Container as GlobalContainer, SpacemasterLogo } from '../../globalStyles'

const LogoContainer = styled.div`
  width: 156px;
`

export const CompanyDetails = styled.div`
  color: white;
  font-size: 1rem;
  
  a {
    color: white;
    text-decoration: none;
  }
`

export const Container = styled(GlobalContainer)`
  background-color: ${({ theme }) => theme.colors.bluewhale};
  padding: 7.5%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 45em) {
    padding: 4% 7.5%;
  }
  margin-top: 4rem;
`

export const Logo = () => (
  <LogoContainer>
    <SpacemasterLogo />
  </LogoContainer>
)