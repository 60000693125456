import React from 'react'
import {
  Container,
  Header,
  Paragraph,
  Illustration,
  InnerContainer,
  Content
} from './Section.elements'

import { StaticImage } from 'gatsby-plugin-image'

export function Hovedkontor () {
  return (
    <Container withSpacing>
      <InnerContainer>
        <Illustration>
          <StaticImage
            src="../../images/vs.png"
            placeholder="blurred"
            layout="constrained"
            width={500}
            alt=""
          />
        </Illustration>
        <Content>
          <Header>Hovedkontor vs lokal afdeling</Header>
          <Paragraph>Spacemasters indkøbsportal er udviklet med fokus på samspillet mellem hovedkontoret og den lokale afdeling eller den enkelte forretning.</Paragraph>
          <Paragraph>Via Spacemaster kan hovedkontoret f.eks. sammensætte pakker, som udsendes til mange lokationer, men det er også muligt for den lokale afdeling selv at bestille til eget forbrug.</Paragraph>
        </Content>
      </InnerContainer>
    </Container>
  )
}

export function MangeLokationer () {
  return (
    <Container withSpacing>
      <InnerContainer>
        <Content>
          <Header>Send til mange lokationer</Header>
          <Paragraph>Spacemaster har gjort det let at håndtere interne bestillinger til og fra mange lokationer. Du har online aktuel status på alle leverancer.  Det samme har modtageren. Derfor slipper du for at bruge tid på tæt opfølgning.</Paragraph>
        </Content>
        <Illustration>
          <StaticImage
            src="../../images/mange_lokationer_big.png"
            placeholder="blurred"
            layout="constrained"
            width={500}
            alt=""
          />
        </Illustration>
      </InnerContainer>
    </Container>
  )
}
