import styled from 'styled-components'
import { Container as GlobalContainer } from '../../globalStyles'

export const Container = styled(GlobalContainer)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  & > * {
    flex: 1 1 295px;
    & + * {
      margin: 2em 0 0 0;
      @media (min-width: 46em) {
        margin: 0 0 0 2em;
      }
    }
  }
  margin-bottom: 3em;

  ${({ reversed }) => reversed && `
    flex-direction: row-reverse;
    & > * {
      margin: 0;
      @media (min-width: 46em) {
        margin: 0 2em 0 0;
      }
      & + * {
      margin: 2em 0 0 0;
      @media (min-width: 46em) {
        margin: 0 2em 0 2em;
      }
    }
    }
  `}
`
export const Header = styled.h1`
  font-size: 2rem;
  line-height: 1.3;
  font-weight: 600;
`

export const Paragraph = styled.p`
  font-size: 1rem;
  margin-top: 1em;
`

export const Content = styled(GlobalContainer)``