import React from 'react'
import styled from 'styled-components'
import { Container as GlobalContainer } from '../../globalStyles'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
export { Button } from '../../globalStyles' 

export const Container = styled(GlobalContainer)`
  padding: 3em 0;
  text-align: center;
  @media (min-width: 45em) {
    padding: 5em 0;
  }
`

export const Header = styled.h1`
  font-size: 2rem;
  line-height: 1.3;
  font-weight: 600;
`

export const Paragraph = styled.p`
  margin-top: 1em;
  font-size: 1rem;
`

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1.5em;
  align-items: end;
  margin-top: 2em;
  padding: 0 0.5em;

  grid-template-areas: "a a";
  padding: 0;

  @media (min-width: 45em) {
    margin-top: 1em;
    grid-template-areas: "a a a a";
  }
`

export const Overview = () => {
  const { allCarouselItemsJson: { nodes }} = useStaticQuery(
    graphql`
      query {
        allCarouselItemsJson {
          nodes {
            id
            title
            src {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    `
  )

  return (
    <Wrapper>
      {
        nodes.map(item => {
          const image = getImage(item.src)
          return (
            <GatsbyImage key={item.id} image={image} alt={item.title} backgroundColor="transparent" />
          )
        })
      }
    </Wrapper>
  )
}