import React from 'react'
import styled from 'styled-components'
import { CompanyDetails, Container, Logo } from './Footer.elements'
import { Button } from '../../globalStyles'

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  padding-bottom: 80px;
  padding-top: 0px;
  max-width: 1440px;
  align-items: flex-start;

  article {
    display: flex;
    flex-direction: column;
    padding: 20px 0;

    h3 {
      color: #fff;
    }

    a {
      color: #fff;
      text-decoration: none;
    }
  }

  @media (min-width: 515px) {
    flex-direction: row;
    width: 100%;
    align-items: flex-end;
  }
`

export default function Footer () {
  return (
      <Container fullWidth>
      <FooterWrapper>
        <article>
          <h3>Kontakt os</h3>
          <a href="tel:+4545347777">Tlf. +45 45 34 77 77</a>
          <a href="mailto:jacob.meibom@crowdmedia.dk">jacob.meibom@crowdmedia.dk</a>
        </article>
        <atricle>
          <CompanyDetails><b>Crowd Media International ApS</b><br />Park Alle 382<br />2625 Vallensbæk<br />CVR 37817023</CompanyDetails>
        </atricle>
        <article>
          <Logo />
          <Button href="mailto://jacob.meibom@crowdmedia.dk?Subject=Spacemaster.io gratis i 3 måneder" target="_top">Prøv gratis i 3 måneder</Button>
        </article>
      </FooterWrapper>
    </Container>
  )
}
