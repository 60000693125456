import React from 'react'
import { ThemeProvider } from 'styled-components'
import GlobalStyle, { theme } from '../../globalStyles'

export default function App ({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      { children }
    </ThemeProvider>
  )
}