import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled, { createGlobalStyle } from 'styled-components'
import { Link } from 'gatsby'

export const theme = {
  colors: {
    white: '#FFFFFF',
    whitelilac: '#F6F9FC',
    mountainmeadow: '#2BC192',
    texasrose: '#F2A349',
    resolutionblue: '#2E4578',
    bluewhale: '#1E2D48',
    bleudefrance: '#3C9AFD',
    mediumslateblue: '#7655FB'
  }
}

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  :root {
    font-size: 16px;
  }

  body {
    margin: 0;
    line-height: 1.6;
    font-family: 'Mukta Vaani', sans-serif;
    color: ${theme.colors.resolutionblue};
    min-width: 20em;
  }

  h1,
  h2, 
  h3,
  p {
    margin: 0;
  }
`

export const Container = styled.div`
  margin: 0 auto;
  width: 85%;
  max-width: 75em;
  background-color: ${theme.colors.white};
  ${({ fullWidth }) => fullWidth && `
      width: 100%;
      max-width: 100%;
    `}
  * + * {
    margin-top: ${({ withSpacing }) => withSpacing && '2em' };
    @media (max-width: 46em) {
      margin-top: ${({ withSpacing }) => withSpacing && '1em' };
    }
  }
`

export const Button = styled.a`
  display: inline-block;
  padding: 1em 1.5em;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme, color = 'white' }) => theme.colors[color]};
  font-size: 0.75rem;
  font-weight:400;
  background-color: ${({ theme, bgColor = 'texasrose' }) => theme.colors[bgColor]};
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
`

export const SpacemasterLogo = () => (
  <StaticImage src="images/logo.png" alt="Spacemaster.io" placeholder="tracedSVG" />
)

export const ListItem = styled.li``

export const List = styled.ul`
  list-style-type: none;
  padding-left: 0.5em;
  text-indent: -0.5em;
  margin-left: 1em;

  ${ListItem} {
    &:before {
      position: relative;
      content: ' ';
      display: inline-block;
      width: 9px;
      height: 9px;
      left: -1em;
      background-color: ${({ theme, bulletColor = 'bluewhale' }) => theme.colors[bulletColor]};
    }
    font-size: 1rem;
  }
`
export default GlobalStyle