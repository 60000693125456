import * as React from "react"
import App from '../components/shared/App'
import Hero from '../components/Hero'
import TailorMade from '../components/TailorMade'
import { Hovedkontor, MangeLokationer } from '../components/ContainedSection'
import { ProduktKatalog, LayoutSkabeloner, Bestilling, TrackAndTrace, Genbestilling, Budgetkontrol } from '../components/Section'
import Footer from '../components/Footer'

const IndexPage = () => {
  return (
    <App>
      <Hero />
      <TailorMade />
      <Hovedkontor />
      <ProduktKatalog />
      <LayoutSkabeloner />
      <Bestilling />
      <MangeLokationer />
      <TrackAndTrace />
      <Genbestilling />
      <Budgetkontrol />
      <Footer />
    </App>
  )
}

export default IndexPage
