import styled from 'styled-components'
export { Button, Container } from '../../globalStyles' 

export const InnerContainer = styled.div`
  background-color: #F6F9FC;
  border-radius: 16px;
  padding: 4%;
  margin: 0 0 4em;
  @media (min-width: 46em) {
    display: grid;
    grid-auto-columns: 1fr;
    grid-gap: 4em;
    grid-template-areas: "content illustration";
    align-items: center;
  }
`

export const Header = styled.h1`
  font-size: 1.875rem;
  line-height: 1.3;
  font-weight: 600;

  &:after {
    content: ' ';
    display: block;
    top: 0.15em;
    border-bottom: 1px solid #2E4578;
    width: 2.5em;
    position: relative;

    @media (min-width: 46em) {
      top: 0.3em;
      font-size: 2rem;
    }
  }
`

export const Paragraph = styled.p`
  font-size: 1rem;
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 5% 7.5%;
  @media (min-width: 46em) {
    margin: 0;
  }
`

export const Illustration = styled.div`
  margin: 0 -1.7em;
  display: flex;
  place-content: center;
`